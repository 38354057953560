<template>
  <div class="oa-approve">
    <van-popup v-model="addPopupVisible" position="top" get-container="body">
      <chapter-project
        :options="options"
        :result="result"
        @select="handleAddSelect"
        :title="title"
      />
    </van-popup>
    <div class="box">
      {{ this.$route.query.userName }}{{ this.$route.query.phone }}申请加入组织
    </div>
    <div class="box" @click="openSelectList(0)">
      <div class="box-title">角色</div>
      <div class="box-title">
        {{ roleText }}<i class="iconfont icon-child-on" style="color:#CCC"></i>
      </div>
    </div>
    <div class="box" @click="openSelectList(1)">
      <div class="box-title">部门</div>
      <div class="box-title">
        {{ departmentText
        }}<i class="iconfont icon-child-on" style="color:#CCC"></i>
      </div>
    </div>
    <div class="box">
      <div class="box-title">职务</div>
      <div class="box-title">
        <van-field
          v-model="duty"
          placeholder="请输入职务"
          input-align="right"
        />
      </div>
    </div>
    <div class="btn_box">
      <van-button type="info" color="#FF6600" @click="save('CERTIFIED_FAILED')"
        >驳回</van-button
      ><van-button type="info" @click="save('APPROVED')">批准</van-button>
    </div>
    <join-organization />
  </div>
</template>

<script>
import goBackMixin from '@mixins/goBack'

import ChapterProject from '../../components/chapterProject'

import { mapState, mapActions } from 'vuex'
import JoinOrganization from '@/components/join-organization'
export default {
  name: 'oa-approve',
  mixins: [goBackMixin],
  components: {
    JoinOrganization,
    ChapterProject
  },
  data() {
    return {
      roleList: [],
      departmentList: [],
      options: [],
      result: [],
      roleResult: [],
      departmentResult: [],
      addPopupVisible: false,
      duty: '',
      type: 0,
      departmentText: '',
      roleText: '',
      id: 0
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      this.getRole()
      this.getDepartment()
    },
    // 获取角色
    getRole() {
      this.$axios
        .get(
          `${this.$base}/management/organization/${this.organizationId}/role`
        )
        .then(res => {
          const { data, code } = res.data
          if (code === 200) {
            data.map(item => {
              this.roleList.push({
                label: item.name,
                value: item.id
              })
            })
          }
        })
    },
    // 获取部门
    getDepartment() {
      this.$axios
        .get(
          `${this.$base}/management/organization/${
            this.organizationId
          }/department`
        )
        .then(res => {
          const { data, code } = res.data
          if (code === 200) {
            data.map(item => {
              this.departmentList.push({
                label: item.name,
                value: item.id
              })
            })
          }
        })
    },
    openSelectList(type) {
      this.result = []
      if (type === 0) {
        this.type = 0
        this.options = this.roleList
        this.title = '角色'
        this.result = this.deepCopy(this.roleResult)
      } else {
        this.type = 1
        this.title = '部门'
        this.options = this.departmentList
        this.result = this.deepCopy(this.departmentResult)
      }
      console.log('roleResult', this.roleResult)
      console.log('departmentResult', this.departmentResult)
      this.addPopupVisible = true
    },
    handleAddSelect(result) {
      console.log('result', result)
      if (this.type === 0) {
        this.roleResult = this.deepCopy(result)
        this.roleText = this.formatResult(result)
      } else {
        this.departmentResult = this.deepCopy(result)
        this.departmentText = this.formatResult(result)
      }
      this.addPopupVisible = false
    },
    formatResult(result) {
      // console.log(result)
      let list = []
      if (this.type === 0) {
        list = this.roleList
      } else {
        list = this.departmentList
      }
      var text = ''
      result.map(item => {
        list.map(items => {
          if (item === items.value) {
            text = text + ',' + items.label
          }
        })
      })
      text = text.slice(1, text.length)
      return text
    },
    // 数组深拷贝
    deepCopy(o) {
      var n
      if (o instanceof Array) {
        n = []
        for (var j = 0; j < o.length; ++j) {
          n[j] = this.deepCopy(o[j])
        }
        return n
      } else if (o instanceof Object) {
        n = {}
        for (var i in o) {
          n[i] = this.deepCopy(o[i])
        }
        return n
      } else {
        return o
      }
    },
    // 批准和驳回
    save(progress) {
      // this.$bus.$emit('join-org', { show: true })
      if (progress === 'APPROVED') {
        if (this.roleResult.length === 0) {
          return this.$notify('至少选择一个角色')
        }
        if (this.departmentResult.length === 0) {
          return this.$notify('至少选择一个部门')
        }
      }
      const departments = this.departmentResult.map(item => {
        return { id: item }
      })
      const roles = this.roleResult.map(item => {
        return { id: item }
      })
      const params = {
        duty: this.duty,
        roles: roles,
        departments: departments
      }
      this.$axios
        .put(
          `${this.$base}/management/user/org/apply/${this.id}/${progress}`,
          params
        )
        .then(res => {
          if (res.data.code === 200) {
            this.$notify({
              message: res.data.message,
              duration: 1000,
              background: '#1989fa'
            })
            setTimeout(() => {
              this.backToNative()
            }, 1000)
          } else {
            this.$notify(res.data.message)
            setTimeout(() => {
              this.backToNative()
            }, 1000)
          }
        })
        .catch(() => {
          this.$notify('审批失败，请重试')
          setTimeout(() => {
            this.backToNative()
          }, 1000)
        })
    }
  },
  async created() {
    await this.getId()
    this.id = this.$route.query.id
  },
  mounted() {}
}
</script>

<style lang="stylus">
.oa-approve
  width 100%
  height 100%
  position relative
  font-size 16px
  & .btn_box
    width 100%
    display flex
    flex-direction row
    align-items center
    justify-content space-around
    margin-top 30px
  & .box
    padding 10px 20px
    border-bottom 1px solid #CCC
    display flex
    align-items center
    justify-content space-between
    & .box-title
      & .van-cell
        padding 0
</style>
