var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-popup-comp" }, [
    _c("div", { staticClass: "title van-hairline--bottom" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c(
      "div",
      [
        _c(
          "van-checkbox-group",
          {
            on: { change: _vm.change },
            model: {
              value: _vm.resultCopy,
              callback: function ($$v) {
                _vm.resultCopy = $$v
              },
              expression: "resultCopy",
            },
          },
          [
            _c(
              "van-cell-group",
              _vm._l(_vm.options, function (option, i) {
                return _c(
                  "van-cell",
                  {
                    key: i,
                    attrs: { clickable: "", title: option.label },
                    on: {
                      click: function ($event) {
                        return _vm.onClick(option, i)
                      },
                    },
                  },
                  [
                    _c("van-checkbox", {
                      ref: "checkboxes",
                      refInFor: true,
                      attrs: {
                        slot: "right-icon",
                        name: option.value,
                        shape: "square",
                      },
                      slot: "right-icon",
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "chapter-project-btn" },
      [
        _c(
          "van-button",
          { attrs: { type: "info", size: "small" }, on: { click: _vm.select } },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }